import { useMemo } from 'react';

import { routes } from 'config/navigation';
import { useUser } from 'providers/AuthProvider';
import useYesterdayRevenue from './useYesterdayRevenue';

const useNavPages = () => {
  const {
    user: { available_pages },
  } = useUser();

  const yesterdayRevenue = useYesterdayRevenue();

  const getBadge = (url: string | undefined) => {
    if (!url) return null;
    return (
      {
        '/revenue': yesterdayRevenue,
        '/locations': 'Preview',
      }[url] || null
    );
  };

  const canVisitURL = (url: string | undefined) => {
    if (process.env.REACT_APP_ENABLE_ALL_PAGES) return true;
    if (!url) return false;
    return availablePages
      .filter((page) => page.url != null)
      .map((page) => page.url)
      .includes(url);
  };

  const availablePages = useMemo(() => {
    return routes.filter((route) => {
      return (
        route.url && available_pages.includes(route.url.replaceAll('/', ''))
      );
    });
  }, [available_pages]);

  const lockedPages = useMemo(() => {
    return routes.filter((route) => {
      return (
        route.url && !available_pages.includes(route.url.replaceAll('/', ''))
      );
    });
  }, [available_pages]);

  const comingSoonPages = useMemo(() => {
    return routes.filter((route) => {
      return !route.url;
    });
  }, []);

  return {
    availablePages,
    lockedPages,
    comingSoonPages,
    getBadge,
    canVisitURL,
  };
};

export default useNavPages;
