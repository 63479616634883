import { createContext, FC, ReactNode, useContext, useState } from 'react';
import { noop } from 'helpers/utils';

interface RetentionPageContextState {
  selectedFields: string[];
  setSelectedFields: (value: string[]) => unknown;
}

const RetentionPageContext = createContext<RetentionPageContextState>({
  selectedFields: [],
  setSelectedFields: noop,
});

const RetentionPageProvider: FC<{ children?: ReactNode }> = ({ children }) => {
  const [selectedFields, setSelectedFields] = useState<string[]>([]);
  return (
    <RetentionPageContext.Provider
      value={{ selectedFields, setSelectedFields }}
    >
      {children}
    </RetentionPageContext.Provider>
  );
};

export const useRetentionPage = () => {
  return useContext(RetentionPageContext);
};

export default RetentionPageProvider;
