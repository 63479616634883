import { useLocation } from 'react-router-dom';

import NavItem from './NavItem';
import NavFooterItem from './NavFooterItem';

import { logoutUser } from 'helpers/auth';

import { ReactComponent as Logo } from 'images/axll-logo.svg';
import { ReactComponent as ResetIcon } from 'icons/reset.svg';
import { ReactComponent as LogoutIcon } from 'icons/logout.svg';

import useNavPages from 'hooks/useNavPages';
import useRefreshData from 'hooks/useRefreshData';

const Sidebar = () => {
  const location = useLocation();
  const pathname = location.pathname;
  const refreshData = useRefreshData();

  const { availablePages, getBadge, lockedPages, comingSoonPages } =
    useNavPages();

  return (
    <>
      <div>
        <div className="px-4 mb-9">
          <Logo className="h-6" />
        </div>
        <div className="mb-6">
          {availablePages.map(({ url, title, icon }) => (
            <NavItem
              key={title}
              active={pathname === url}
              href={url}
              icon={icon}
              badge={getBadge(url)}
            >
              {title}
            </NavItem>
          ))}
        </div>
        {lockedPages.length > 0 && (
          <>
            <p className="uppercase text-xs text-axll-dark-grey font-medium px-4 tracking-wide mb-4">
              Locked
            </p>
            <div className="mb-6">
              {lockedPages.map(({ title, icon }) => (
                <NavItem key={title} disabled icon={icon}>
                  {title}
                </NavItem>
              ))}
            </div>
          </>
        )}
        {comingSoonPages.length > 0 && (
          <>
            <p className="uppercase text-xs text-axll-dark-grey font-medium px-4 tracking-wide mb-4">
              Coming soon
            </p>
            <div>
              {comingSoonPages.map(({ title, icon }) => (
                <NavItem key={title} disabled icon={icon}>
                  {title}
                </NavItem>
              ))}
            </div>
          </>
        )}
      </div>
      <div>
        <NavFooterItem
          onClick={refreshData}
          icon={<ResetIcon className="h-5" />}
        >
          Refresh data
        </NavFooterItem>
        <NavFooterItem
          icon={<LogoutIcon className="h-5" />}
          onClick={logoutUser}
        >
          Logout
        </NavFooterItem>
      </div>
    </>
  );
};

export default Sidebar;
