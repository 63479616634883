import { FC, ReactNode } from 'react';
import { createPortal } from 'react-dom';

const container = document.querySelector('#modal-root');

const Portal: FC<{ children?: ReactNode }> = ({ children }) => {
  if (!container || !children) return null;
  return createPortal(children, container);
};

export default Portal;
