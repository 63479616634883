import { QueryFunctionContext } from '@tanstack/react-query';

import httpClient from 'http/httpClient';
import {
  AvailableDatesResponse,
  RevenueChannelData,
  RevenueChannelsResponse,
  RevenueChartDataResponse,
  RevenueResponse,
} from 'types/revenue';

export const getAvailableDates = async () => {
  const { data } = await httpClient.get<AvailableDatesResponse>(
    '/revenue/available-dates',
  );
  return data;
};

export const getRevenue = async (context: QueryFunctionContext) => {
  const from = context.queryKey[1];
  const to = context.queryKey[2];
  const interval = context.queryKey[3];
  const filterBy = context.queryKey[4];

  const { data } = await httpClient.get<RevenueResponse>(
    `/revenue?from=${from}&to=${to}&interval=${interval}&filter_by=${filterBy}`,
  );
  return data;
};

export const getRevenueChartData = async (context: QueryFunctionContext) => {
  const { data } = await httpClient.get<RevenueChartDataResponse>(
    context.queryKey[0] as string,
  );
  return data;
};

export const getRevenueChannels = async (context: QueryFunctionContext) => {
  const { data } = await httpClient.get<RevenueChannelsResponse>(
    '/revenue/channels',
  );
  return data;
};

export const setRevenueChannelGoals = async (payload: RevenueChannelData[]) => {
  await httpClient.post('/revenue/channels/goal', payload);
};
