import { FC, ReactNode } from 'react';
import { useLocation } from 'react-router-dom';

import useNavPages from 'hooks/useNavPages';
import Redirect from 'components/shared/Redirect';

const EnsureCanVisitPageMiddleware: FC<{
  children?: ReactNode;
  url?: string;
}> = ({ children, url }) => {
  const { pathname } = useLocation();
  const { canVisitURL, availablePages } = useNavPages();

  if (canVisitURL(url ? url : pathname)) return <>{children}</>;
  return <Redirect to={availablePages[0].url!} />;
};

export default EnsureCanVisitPageMiddleware;
