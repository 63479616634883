import { ReactNode } from 'react';

export enum ProductsView {
  SALES = 'sales',
  UNITS = 'units',
}

export enum ProductsInterval {
  DAILY = 'daily',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
  QUARTERLY = 'quarterly',
}

export type TableCellType = 'primary' | 'secondary';

export interface TableCell {
  content?: ReactNode;
  type?: TableCellType;
  productChartKeyID?: string;
}

export interface ProductsTableData {
  headers: TableCell[];
  rows: TableCell[][];
}

export interface SingleProductDataResponse {
  fields: string[];
  data: {
    date: string;
    [key: string]: string;
  }[];
}
