import { FC, ReactNode, useEffect } from 'react';
import { useTimeoutFn } from 'react-use';

import { ReactComponent as InfoIcon } from 'icons/info.svg';
import { ReactComponent as ErrorIcon } from 'icons/error.svg';
import { ReactComponent as SuccessIcon } from 'icons/success.svg';
import { ReactComponent as WarningIcon } from 'icons/warning.svg';

import { Notification as NotificationType } from 'types/notification';

const notificationClassName =
  'notification pointer-events-auto flex items-center gap-4 drop-shadow-md w-max max-w-full text-sm font-medium p-5 rounded-xl';

const Success: FC<{ children?: ReactNode }> = ({ children }) => (
  <div className={`${notificationClassName} text-axll-green bg-[#F7FFF9]`}>
    <SuccessIcon className="w-4 flex-shrink-0" />
    {children}
  </div>
);

const Info: FC<{ children?: ReactNode }> = ({ children }) => (
  <div className={`${notificationClassName} text-axll-blue bg-[#F4F9FF]`}>
    <InfoIcon className="w-5 h-5 flex-shrink-0" />
    {children}
  </div>
);

const Error: FC<{ children?: ReactNode }> = ({ children }) => (
  <div className={`${notificationClassName} text-axll-red bg-[#FFF7F7]`}>
    <ErrorIcon className="w-3 h-3 flex-shrink-0" />
    {children}
  </div>
);

const Warning: FC<{ children?: ReactNode }> = ({ children }) => (
  <div className={`${notificationClassName} text-axll-yellow bg-[#FFFCF7]`}>
    <WarningIcon className="w-5 flex-shrink-0" />
    {children}
  </div>
);

const Notification = ({
  message,
  remove,
  type = 'info',
  timeout = 0,
}: NotificationType) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, cancel, reset] = useTimeoutFn(() => {
    if (remove) remove();
  }, timeout ?? 2_147_483_647);

  useEffect(() => {
    cancel();
    reset();
  }, [cancel, reset, type, message, timeout]);

  if (type === 'info') return <Info>{message}</Info>;
  if (type === 'success') return <Success>{message}</Success>;
  if (type === 'error') return <Error>{message}</Error>;
  return <Warning>{message}</Warning>;
};

export default Notification;
