import { useQuery } from '@tanstack/react-query';
import { getAvailableDates } from 'api/revenue';

const useAvailableDates = () => {
  const queryData = useQuery(['available-dates'], getAvailableDates, {
    cacheTime: 1000 * 60 * 30,
    staleTime: 1000 * 60 * 30,
  });
  return queryData;
};
export default useAvailableDates;
