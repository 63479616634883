import dayjs from 'dayjs';

import { DateRange, DateRangeOptions } from 'types/date';
import { RevenueDisplayInterval } from 'types/revenue';

export const getCurrentDate = () => {
  return dayjs().format('YYYY-MM-DD');
};

export const getLast7Days = (): DateRange => {
  const yesterday = dayjs().subtract(1, 'day');
  return {
    from: yesterday.subtract(6, 'day').format('YYYY-MM-DD'),
    to: yesterday.format('YYYY-MM-DD'),
  };
};

export const getLastWeek = (): DateRange => {
  const lastWeek = dayjs().subtract(1, 'week');
  return {
    from: lastWeek.startOf('week').format('YYYY-MM-DD'),
    to: lastWeek.endOf('week').format('YYYY-MM-DD'),
  };
};

export const getLast30Days = (): DateRange => {
  const yesterday = dayjs().subtract(1, 'day');
  return {
    from: yesterday.subtract(29, 'day').format('YYYY-MM-DD'),
    to: yesterday.format('YYYY-MM-DD'),
  };
};

export const getLastMonth = (): DateRange => {
  const lastMonth = dayjs().subtract(1, 'month');
  return {
    from: lastMonth.startOf('month').format('YYYY-MM-DD'),
    to: lastMonth.endOf('month').format('YYYY-MM-DD'),
  };
};

export const getLast365Days = (): DateRange => {
  const yesterday = dayjs().subtract(1, 'day');
  return {
    from: yesterday.subtract(365, 'day').format('YYYY-MM-DD'),
    to: yesterday.format('YYYY-MM-DD'),
  };
};

export const getWeekToDate = (): DateRange => {
  const yesterday = dayjs().subtract(1, 'day');
  return {
    from: yesterday.startOf('week').format('YYYY-MM-DD'),
    to: yesterday.format('YYYY-MM-DD'),
  };
};

export const getMonthToDate = (): DateRange => {
  const yesterday = dayjs().subtract(1, 'day');
  return {
    from: yesterday.startOf('month').format('YYYY-MM-DD'),
    to: yesterday.format('YYYY-MM-DD'),
  };
};

export const getYearToDate = (): DateRange => {
  const yesterday = dayjs().subtract(1, 'day');
  return {
    from: yesterday.startOf('year').format('YYYY-MM-DD'),
    to: yesterday.format('YYYY-MM-DD'),
  };
};

export const formatKPIDate = (
  date: string,
  interval: RevenueDisplayInterval,
) => {
  if (interval === RevenueDisplayInterval.DAILY) {
    return dayjs(date).format('MMMM D');
  } else {
    return dayjs(date).format('MMMM YYYY');
  }
};

export const getSelectFieldOption = ({
  from,
  to,
}: DateRange): DateRangeOptions => {
  const { from: last7DaysFrom, to: last7DaysTo } = getLast7Days();
  const { from: last30DaysFrom, to: last30DaysTo } = getLast30Days();
  const { from: lastWeekFrom, to: lastWeekTo } = getLastWeek();
  const { from: lastMonthFrom, to: lastMonthTo } = getLastMonth();
  const { from: last365DaysFrom, to: last365DaysTo } = getLast365Days();
  const { from: weekToDateFrom, to: weekToDateTo } = getWeekToDate();
  const { from: monthToDateFrom, to: monthToDateTo } = getMonthToDate();
  const { from: yearToDateFrom, to: yearToDateTo } = getYearToDate();

  if (from === last7DaysFrom && to === last7DaysTo)
    return DateRangeOptions.LAST7DAYS;
  if (from === last30DaysFrom && to === last30DaysTo)
    return DateRangeOptions.LAST30DAYS;
  if (from === lastWeekFrom && to === lastWeekTo)
    return DateRangeOptions.LASTWEEK;
  if (from === lastMonthFrom && to === lastMonthTo)
    return DateRangeOptions.LASTMONTH;
  if (from === last365DaysFrom && to === last365DaysTo)
    return DateRangeOptions.LAST365DAYS;
  if (from === weekToDateFrom && to === weekToDateTo)
    return DateRangeOptions.WEEKTODATE;
  if (from === monthToDateFrom && to === monthToDateTo)
    return DateRangeOptions.MONTHTODATE;
  if (from === yearToDateFrom && to === yearToDateTo)
    return DateRangeOptions.YEARTODATE;
  return DateRangeOptions.CUSTOM;
};

export const getDateRangeFromSelectOption = (
  option: DateRangeOptions,
): DateRange => {
  const last7DaysRange = getLast7Days();
  const last30DaysRange = getLast30Days();
  const lastWeekRange = getLastWeek();
  const lastMonthRange = getLastMonth();
  const last365DaysRange = getLast365Days();
  const weekToDateRange = getWeekToDate();
  const monthToDateRange = getMonthToDate();
  const yearToDateRange = getYearToDate();

  if (option === DateRangeOptions.LAST7DAYS) return last7DaysRange;
  if (option === DateRangeOptions.LAST30DAYS) return last30DaysRange;
  if (option === DateRangeOptions.LASTWEEK) return lastWeekRange;
  if (option === DateRangeOptions.LASTMONTH) return lastMonthRange;
  if (option === DateRangeOptions.LAST365DAYS) return last365DaysRange;
  if (option === DateRangeOptions.WEEKTODATE) return weekToDateRange;
  if (option === DateRangeOptions.MONTHTODATE) return monthToDateRange;
  return yearToDateRange;
};
