import { useAsyncFn } from 'react-use';
import { useEffect, useRef } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import { LOCALSTORAGE_CACHE_KEY } from 'config/localStorage';
import { useNotifications } from 'providers/NotificationProvider';

const useRefreshData = () => {
  const queryClient = useQueryClient();
  const { notify, updateNotification } = useNotifications();

  const notificationFunctionRef = useRef(updateNotification);

  useEffect(() => {
    notificationFunctionRef.current = updateNotification;
  }, [updateNotification]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, refresh] = useAsyncFn(async () => {
    const id = notify({
      type: 'info',
      message: 'Refreshing the data...',
      timeout: null,
    });
    try {
      localStorage.removeItem(LOCALSTORAGE_CACHE_KEY);
      await queryClient.invalidateQueries();
      notificationFunctionRef.current(id, {
        type: 'success',
        message: 'Data successfully refreshed.',
        timeout: 3000,
      });
    } catch {
      notificationFunctionRef.current(id, {
        type: 'error',
        message: 'Unable to refresh data at this time.',
        timeout: 3000,
      });
    }
  }, [updateNotification]);

  return refresh;
};

export default useRefreshData;
