import axios from 'axios';
import { User } from 'types/user';

import httpClient from 'http/httpClient';
import { LoginResponseData } from 'types/auth';

export const loginUser = async (email: string, password: string) => {
  try {
    const { data } = await httpClient.post<LoginResponseData>('/login', {
      email,
      password,
    });
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response?.status === 403) {
        throw Error('Invalid credentials');
      }
    }
    throw Error('Error trying to log in. Please try again');
  }
};

export const getCurrentUser = async () => {
  const { data } = await httpClient.get<User>('/me');
  return data;
};
