import { FC, ReactNode } from 'react';

import useChannelColors from 'hooks/useChannelColors';
import useAvailableDates from 'hooks/useAvailableDates';

import ErrorScreen from 'components/shared/ErrorScreen';
import LoadingScreen from 'components/shared/LoadingScreen';

const InitialDataMiddleware: FC<{ children: ReactNode }> = ({ children }) => {
  const { isError: isErrorChannelColors, isLoading: isLoadingChannelColors } =
    useChannelColors();
  const { isError: isErrorAvailableDates, isLoading: isLoadingAvailableDates } =
    useAvailableDates();

  const isError = isErrorChannelColors || isErrorAvailableDates;
  const isLoading = isLoadingAvailableDates || isLoadingChannelColors;

  if (isLoading) return <LoadingScreen />;
  if (isError)
    return (
      <ErrorScreen>
        There has been an error launching the app. Please reload the window.
      </ErrorScreen>
    );

  return <>{children}</>;
};

export default InitialDataMiddleware;
