import { useQuery } from '@tanstack/react-query';

import { getRevenue } from 'api/revenue';
import { mapRevenueToDailySales } from 'helpers/mappers';

import useYesterdayDate from './useYesterdayDate';

const useYesterdayRevenue = (overrideDate?: string) => {
  const yesterdayDate = useYesterdayDate();
  const date = overrideDate ? overrideDate : yesterdayDate;
  const queryData = useQuery(['revenue', date, date, 'daily'], getRevenue);

  if (queryData.data) {
    return mapRevenueToDailySales(queryData.data);
  }

  return null;
};
export default useYesterdayRevenue;
