import { ReactNode } from 'react';
import { ReactComponent as XCircleIcon } from 'icons/x-circle.svg';

interface ErrorAlertProps {
  title?: ReactNode;
  description?: ReactNode;
}

const ErrorAlert = ({
  title = 'There has been an error.',
  description,
}: ErrorAlertProps) => {
  return (
    <div className="rounded-2xl w-full bg-red-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-red-800">{title}</h3>
          {description && (
            <div className="mt-2 text-sm text-red-700">
              <div className="space-y-1">{description}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ErrorAlert;
