export const repeat = (times: number) => {
  return Array.from(Array(times).keys());
};

export const noop = () => undefined;

export const wait = (milliseconds: number) => {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
};

export const calculatePercentageDifference = (
  a: number | undefined,
  b: number | undefined,
) => {
  if (a && b) {
    return `${(((a - b) / b) * 100).toFixed(2)}%`;
  }
  return 'N/A';
};

export const formatNumberToMoneyString = (a: number | undefined) => {
  if (typeof a === 'number') {
    return `$${a.toLocaleString()}`;
  }
  return 'N/A';
};

export const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

export const constructURL = (url: string, params?: URLSearchParams) => {
  const urlWithoutQueryString = url.split('?')[0];
  if (!params) return urlWithoutQueryString;
  return `${urlWithoutQueryString}?${params.toString()}`;
};

export const getClosestArrayElement = (needle: number, haystack: unknown[]) => {
  const closest = haystack.reduce((a, b) => {
    return Math.abs(Number(b) - needle) < Math.abs(Number(a) - needle)
      ? Number(b)
      : Number(a);
  });
  return closest as number;
};
