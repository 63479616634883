import {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useRef,
} from 'react';
import { useList } from 'react-use';

import {
  NotificationContextType,
  Notification as NotificationType,
} from 'types/notification';

import Portal from 'components/shared/Portal';
import Notification from 'components/notifications/Notification';

const NotificationContext = createContext<NotificationContextType>({
  notify: () => 0,
  updateNotification: () => {},
});

const NotificationProvider: FC<{ children?: ReactNode }> = ({ children }) => {
  const counter = useRef<number>(1);
  const [notifications, { filter, push, updateAt }] = useList<NotificationType>(
    [],
  );

  const notify = useCallback(
    ({ type, message, timeout = 3000 }: NotificationType) => {
      const id = counter.current;
      const remove = () => filter((notification) => notification.id !== id);
      push({ id, remove, type, message, timeout });
      counter.current += 1;
      return id;
    },
    [filter, push],
  );

  const updateNotification = useCallback(
    (id: number, notification: Partial<NotificationType>) => {
      const index = notifications.findIndex((el) => el.id === id);
      if (index !== -1) {
        updateAt(index, {
          ...notifications[index],
          ...notification,
        });
      }
    },
    [updateAt, notifications],
  );

  return (
    <>
      <Portal>
        <div className="fixed flex w-full pointer-events-none flex-col gap-4 items-center z-50 bottom-12 left-1/2 transform -translate-x-1/2 px-4">
          {notifications.map((notification) => (
            <Notification key={notification.id} {...notification} />
          ))}
        </div>
      </Portal>
      <NotificationContext.Provider value={{ notify, updateNotification }}>
        {children}
      </NotificationContext.Provider>
    </>
  );
};

export default NotificationProvider;

export const useNotifications = () => useContext(NotificationContext);
