import {
  RevenueTableData,
  RevenueResponse,
  RevenueAccordionData,
} from 'types/revenue';
import {
  calculatePercentageDifference,
  formatNumberToMoneyString,
} from './utils';

export const mapRevenueToDailyTableData = (
  revenue: RevenueResponse,
): RevenueTableData => {
  let dailySum = 0;
  let weekRollingAvg = 0;
  let monthRollingAvg = 0;

  const channel_data: { [key: string]: string[] } = {};

  revenue.channels.forEach((channel, index) => {
    dailySum += revenue.data['Daily'][index];
    weekRollingAvg += revenue.data['7-Day Rolling Average'][index];
    monthRollingAvg += revenue.data['30-Day Rolling Average'][index];

    channel_data[channel] = [
      formatNumberToMoneyString(revenue.data['Daily'][index]),
      formatNumberToMoneyString(revenue.data['7-Day Rolling Average'][index]),
      formatNumberToMoneyString(revenue.data['30-Day Rolling Average'][index]),
      calculatePercentageDifference(
        revenue.data['Daily'][index],
        revenue.data['30-Day Rolling Average'][index],
      ),
    ];
  });

  const comparisonPercentage = calculatePercentageDifference(
    dailySum,
    monthRollingAvg,
  );

  return {
    featured_data: {
      Daily: formatNumberToMoneyString(dailySum),
      '7-Day Rolling Average': formatNumberToMoneyString(weekRollingAvg),
      '30-Day Rolling Average': formatNumberToMoneyString(monthRollingAvg),
      '% Daily vs 30-Day Avg': comparisonPercentage,
    },
    channel_data,
  };
};

export const mapRevenueToMonthlyTableData = (
  revenue: RevenueResponse,
): RevenueTableData => {
  let goal = 0;
  let mtdActuals = 0;
  let mtdPace = 0;

  const channel_data: { [key: string]: string[] } = {};

  revenue.channels.forEach((channel, index) => {
    goal += revenue.data['Goal'][index];
    mtdActuals += revenue.data['MTD Actuals'][index];
    mtdPace += revenue.data['MTD Pace'][index];

    channel_data[channel] = [
      formatNumberToMoneyString(revenue.data['Goal'][index]),
      formatNumberToMoneyString(revenue.data['MTD Actuals'][index]),
      formatNumberToMoneyString(revenue.data['MTD Pace'][index]),
      calculatePercentageDifference(
        revenue.data['MTD Pace'][index],
        revenue.data['Goal'][index],
      ),
    ];
  });

  const comparisonPercentage = calculatePercentageDifference(mtdPace, goal);

  return {
    featured_data: {
      Goal: formatNumberToMoneyString(goal),
      'MTD Actuals': formatNumberToMoneyString(mtdActuals),
      'MTD Pace': formatNumberToMoneyString(mtdPace),
      'Pace vs Goal': comparisonPercentage,
    },
    channel_data,
  };
};

export const mapRevenueToDailyAccordionData = (
  revenue: RevenueResponse,
): RevenueAccordionData => {
  let dailySum = 0;
  let weekRollingAvg = 0;
  let monthRollingAvg = 0;

  const channels: any = {
    Daily: {},
    '7-Day Rolling Average': {},
    '30-Day Rolling Average': {},
    '% Daily vs 30-Day Avg': {},
  };

  revenue.channels.forEach((channel, index) => {
    dailySum += revenue.data['Daily'][index];
    weekRollingAvg += revenue.data['7-Day Rolling Average'][index];
    monthRollingAvg += revenue.data['30-Day Rolling Average'][index];

    channels.Daily[channel] = formatNumberToMoneyString(
      revenue.data['Daily'][index],
    );
    channels['7-Day Rolling Average'][channel] = formatNumberToMoneyString(
      revenue.data['7-Day Rolling Average'][index],
    );
    channels['30-Day Rolling Average'][channel] = formatNumberToMoneyString(
      revenue.data['30-Day Rolling Average'][index],
    );

    channels['% Daily vs 30-Day Avg'][channel] = calculatePercentageDifference(
      revenue.data['Daily'][index],
      revenue.data['30-Day Rolling Average'][index],
    );
  });

  const comparisonPercentage = calculatePercentageDifference(
    dailySum,
    monthRollingAvg,
  );

  return {
    Daily: {
      value: formatNumberToMoneyString(dailySum),
      channels: channels.Daily,
    },
    '7-Day Rolling Average': {
      value: formatNumberToMoneyString(weekRollingAvg),
      channels: channels['7-Day Rolling Average'],
    },
    '30-Day Rolling Average': {
      value: formatNumberToMoneyString(monthRollingAvg),
      channels: channels['30-Day Rolling Average'],
    },
    '% Daily vs 30-Day Avg': {
      value: comparisonPercentage,
      channels: channels['% Daily vs 30-Day Avg'],
    },
  };
};

export const mapRevenueToMonthlyAccordionData = (
  revenue: RevenueResponse,
): RevenueAccordionData => {
  let goal = 0;
  let mtdActuals = 0;
  let mtdPace = 0;

  const channels: any = {
    Goal: {},
    'MTD Actuals': {},
    'MTD Pace': {},
    'Pace vs Goal': {},
  };

  revenue.channels.forEach((channel, index) => {
    goal += revenue.data['Goal'][index];
    mtdActuals += revenue.data['MTD Actuals'][index];
    mtdPace += revenue.data['MTD Pace'][index];

    channels.Goal[channel] = formatNumberToMoneyString(
      revenue.data['Goal'][index],
    );
    channels['MTD Actuals'][channel] = formatNumberToMoneyString(
      revenue.data['MTD Actuals'][index],
    );
    channels['MTD Pace'][channel] = formatNumberToMoneyString(
      revenue.data['MTD Pace'][index],
    );

    channels['Pace vs Goal'][channel] = calculatePercentageDifference(
      revenue.data['MTD Pace'][index],
      revenue.data['Goal'][index],
    );
  });

  const comparisonPercentage = calculatePercentageDifference(mtdPace, goal);

  return {
    Goal: {
      value: formatNumberToMoneyString(goal),
      channels: channels.Goal,
    },
    'MTD Actuals': {
      value: formatNumberToMoneyString(mtdActuals),
      channels: channels['MTD Actuals'],
    },
    'MTD Pace': {
      value: formatNumberToMoneyString(mtdPace),
      channels: channels['MTD Pace'],
    },
    'Pace vs Goal': {
      value: comparisonPercentage,
      channels: channels['Pace vs Goal'],
    },
  };
};

export const mapRevenueToDailySales = (revenue: RevenueResponse): string => {
  let dailySum = 0;

  revenue.channels.forEach((_, index) => {
    dailySum += revenue.data['Daily'][index];
  });

  return formatNumberToMoneyString(dailySum);
};
