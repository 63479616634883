import { createContext, FC, ReactNode, useContext, useState } from 'react';

import { FinancialsViewMode } from 'types/financials';

interface FinancialsPageContextState {
  fields: string[];
  type: string;
  view: FinancialsViewMode;
  toggleField: (field: string) => void;
  isFieldActive: (field: string) => boolean;
  setType: (value: string) => void;
  setView: (value: FinancialsViewMode) => void;
}

const FinancialsPageContext = createContext<FinancialsPageContextState>({
  fields: [],
  type: 'All',
  view: FinancialsViewMode.TOTALS,
  toggleField: () => undefined,
  isFieldActive: () => false,
  setType: () => undefined,
  setView: () => undefined,
});

const FinancialsPageProvider: FC<{ children?: ReactNode }> = ({ children }) => {
  const [type, setType] = useState('All');
  const [view, setView] = useState<FinancialsViewMode>(
    FinancialsViewMode.TOTALS,
  );
  const [fields, setFields] = useState<string[]>([]);

  const toggleField = (value: string) => {
    if (fields.includes(value)) {
      setFields(fields.filter((field) => field !== value));
    } else {
      setFields([...fields, value]);
    }
  };

  const isFieldActive = (value: string) => fields.includes(value);

  return (
    <FinancialsPageContext.Provider
      value={{
        fields,
        toggleField,
        isFieldActive,
        type,
        setType,
        view,
        setView,
      }}
    >
      {children}
    </FinancialsPageContext.Provider>
  );
};

export const useFinancialsPage = () => useContext(FinancialsPageContext);

export default FinancialsPageProvider;
