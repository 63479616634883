import axios, { AxiosRequestConfig } from 'axios';
import { getToken } from 'helpers/localStorage';

const BASE_URL = process.env.REACT_APP_BASE_URL || '';

const httpClient = axios.create({
  baseURL: BASE_URL,
});

httpClient.interceptors.request.use((config) => {
  if (config.headers) {
    config.headers.Authorization = `Bearer ${getToken()}`;
  }
  return config;
});

httpClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (!axios.isAxiosError(error)) return Promise.reject(error);
    if (!error.response) return Promise.reject(error);

    const config: AxiosRequestConfig<any> = error.response.config;
    const isUnauthorizedError = error.response.status === 401;

    const currentURL = window.location.pathname + window.location.search;

    /**
     * If we're trying to hit a /me route and we get 401,
     * return data as null instead of redirecting to the login page.
     */
    if (config.url === '/me' && isUnauthorizedError) {
      return { data: null };
    }

    /**
     * If we are trying to hit a /login route and we get an error,
     * reject the Promise instead of redirecting to the login page.
     */
    if (config.url === '/login') {
      return Promise.reject(error);
    }

    /**
     * If we're trying to hit any route other than /me route
     * and we get 401, redirect to /login page. Otherwise, reject
     * the promise and let the components handle the error state.
     */
    if (isUnauthorizedError) {
      window.location.href = `/login?redirect_to=${currentURL}`;
    } else {
      return Promise.reject(error);
    }
  },
);

export default httpClient;
