import { ReactNode } from 'react';

export enum FinancialsChartInterval {
  DAILY = 'daily',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
}

export enum FinancialsInterval {
  MONTHLY = 'monthly',
  QUARTERLY = 'quarterly',
}

export type TableCellType = 'primary' | 'secondary';
export interface TableCell {
  content?: ReactNode;
  type?: TableCellType;
  isFinancialChartField?: boolean;
}

export interface FinancialsChartDataResponse {
  fields: string[];
  data: {
    date: string;
    [key: string]: string;
  }[];
}

export interface FinancialsTableData {
  headers: TableCell[];
  rows: TableCell[][];
}
export interface FinancialsTotalsResponse {
  yearly: FinancialsTableData;
  monthly?: FinancialsTableData;
  quarterly?: FinancialsTableData;
}

export interface FinancialsTotalsFiltersResponse {
  filters: string[];
}

export enum FinancialsViewMode {
  TOTALS = 'totals',
  UNITS = 'units',
}
