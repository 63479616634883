const LoadingScreen = () => (
  <div className="flex-1 flex items-center justify-center bg-axll-blue">
    <div className="lds-ripple">
      <div></div>
      <div></div>
    </div>
  </div>
);

export default LoadingScreen;
