import { FC, ReactNode } from 'react';
import { Outlet, useMatch } from 'react-router-dom';

import { useUser } from 'providers/AuthProvider';
import InitialDataMiddleware from 'middleware/InitialDataMiddleware';

import Footer from 'components/shared/Footer';
import Sidebar from 'components/sidebar/Sidebar';
import MobileNavigation from 'components/mobile-navigation/MobileNavigation';

const Aside: FC<{ children?: ReactNode }> = ({ children }) => (
  <aside className="hidden overflow-y-auto fixed inset-y-0 w-[280px] bg-white shadow-lg border-r lg:flex flex-col justify-between py-5">
    {children}
  </aside>
);

const Main: FC = () => {
  const { user } = useUser();
  return (
    <main className="lg:pl-[280px] flex-1 flex flex-col min-h-0">
      <div className="bg-white h-16 border-b flex lg:hidden items-center justify-between text-xl font-medium tracking-tight px-4">
        <span>{user.account_name}</span>
        <MobileNavigation />
      </div>
      <Outlet />
    </main>
  );
};

const AppLayout = () => {
  const isLocationsMap = !!useMatch('/locations/*');
  return (
    <InitialDataMiddleware>
      <div className="flex-1 flex flex-col relative h-full">
        <Aside>
          <Sidebar />
        </Aside>
        <Main />
        {!isLocationsMap && <Footer />}
      </div>
    </InitialDataMiddleware>
  );
};

export default AppLayout;
