import { FC, ReactNode } from 'react';

const ErrorScreen: FC<{ children: ReactNode }> = ({ children }) => (
  <div className="flex-1 flex items-center justify-center bg-axll-blue">
    <div className="container text-white max-w-lg text-lg">
      <p className="text-center">{children}</p>
    </div>
  </div>
);

export default ErrorScreen;
