import { MouseEventHandler, ReactNode } from 'react';
import { Link } from 'react-router-dom';

interface NavItemProps {
  children?: ReactNode;
  href?: string;
  icon?: ReactNode;
  active?: boolean;
  disabled?: boolean;
  badge?: ReactNode;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

const NavFooterItem = ({
  children,
  icon,
  active = false,
  disabled = false,
  href = '',
  badge,
  onClick,
}: NavItemProps) => {
  if (disabled) {
    return (
      <div className="pr-4 select-none tracking-tight">
        <div className="flex items-center gap-4 opacity-50 h-12 px-4">
          <span className="text-axll-dark-grey">{icon}</span>
          <span className="text-axll-dark-grey text-sm font-medium">
            {children}
          </span>
        </div>
      </div>
    );
  }

  if (onClick) {
    return (
      <button className="block pr-4 w-full" type="button" onClick={onClick}>
        <div
          className={`${
            active ? 'bg-axll-light-blue' : 'bg-white hover:bg-axll-light-blue'
          } transition tracking-tight flex items-center justify-between gap-4 h-12 rounded-r-3xl px-4`}
        >
          <div className="flex items-center gap-4">
            <span className="text-black">{icon}</span>
            <span className="text-sm font-medium">{children}</span>
          </div>
          {badge && (
            <div className="h-6 inline-flex px-2 text-xs items-center rounded-2xl border text-axll-dark-grey border-axll-dark-grey font-medium">
              {badge}
            </div>
          )}
        </div>
      </button>
    );
  }

  return (
    <Link to={href} className="block pr-4">
      <div
        className={`${
          active ? 'bg-axll-light-blue' : 'bg-white hover:bg-axll-light-blue'
        } transition tracking-tight flex items-center justify-between gap-4 h-12 rounded-r-3xl px-4`}
      >
        <div className="flex items-center gap-4">
          <span className="text-black">{icon}</span>
          <span className="text-sm font-medium">{children}</span>
        </div>
        {badge && (
          <div className="h-6 inline-flex px-2 text-xs items-center rounded-2xl border text-axll-dark-grey border-axll-dark-grey font-medium">
            {badge}
          </div>
        )}
      </div>
    </Link>
  );
};

export default NavFooterItem;
