import { FC, ReactNode } from 'react';

const MAINTENANCE_MESSAGE = process.env.REACT_APP_MAINTENANCE_MESSAGE;

const MaintenanceMiddleware: FC<{ children?: ReactNode }> = ({ children }) => {
  if (!MAINTENANCE_MESSAGE) return <>{children}</>;
  return (
    <div className="flex-1 flex items-center justify-center bg-axll-blue text-white">
      <div className="container text-center">
        <p className="text-lg font-medium">{MAINTENANCE_MESSAGE}</p>
      </div>
    </div>
  );
};

export default MaintenanceMiddleware;
