import { useMedia } from 'react-use';
import { createContext, FC, ReactNode, useContext } from 'react';

const MobileContext = createContext(false);

const MobileProvider: FC<{ children?: ReactNode }> = ({ children }) => {
  const isMobile = useMedia('(max-width: 1023px)');
  return (
    <MobileContext.Provider value={isMobile}>{children}</MobileContext.Provider>
  );
};

export default MobileProvider;

export const useMobile = () => useContext(MobileContext);
