import { MouseEventHandler } from 'react';

export interface DateRange {
  from: string;
  to: string;
}

export enum DateRangeOptions {
  CUSTOM = 'Custom',
  LAST7DAYS = 'Last 7 days',
  LASTWEEK = 'Last week',
  LAST30DAYS = 'Last 30 days',
  LASTMONTH = 'Last month',
  LAST365DAYS = 'Last 365 days',
  WEEKTODATE = 'Week to date',
  MONTHTODATE = 'Month to date',
  YEARTODATE = 'Year to date',
}

export interface DatePickerInputProps {
  value?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}
