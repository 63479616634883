import { Component, ReactNode } from 'react';
import ErrorAlert from './ErrorAlert';

interface ErrorBoundaryProps {
  children?: ReactNode;
}

interface ErrorBoundaryState {
  isError: boolean;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { isError: false };
  }

  static getDerivedStateFromError() {
    return { isError: true };
  }

  render(): ReactNode {
    if (this.state.isError) {
      return (
        <div className="p-4">
          <div className="p-2 bg-white rounded-3xl shadow">
            <ErrorAlert
              title="Unfortunately, an error has occured."
              description="An unexpected error has occured while trying to load this page. Please contact Axll support."
            />
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
