export interface AvailableDatesResponse {
  from: string;
  to: string;
}

export interface KPI {
  label: string;
  trend: 'up' | 'down';
  value: string;
}

export interface RevenueResponse {
  channels: string[];
  data: {
    [k: string]: number[];
  };
  key_metrics: {
    amount: number;
    kpis: KPI[];
  };
}

export interface RevenueTableData {
  featured_data: {
    [key: string]: string;
  };
  channel_data: {
    [channel: string]: any[];
  };
}

export interface RevenueAccordionData {
  [key: string]: {
    value: string;
    channels: {
      [channel: string]: string;
    };
  };
}

export enum RevenueDisplayInterval {
  MONTHLY = 'monthly',
  DAILY = 'daily',
}

export enum RevenueChartDataType {
  SALES = 'sales',
  UNITS = 'units',
}

export enum RevenueChartInterval {
  DAILY = 'daily',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
  QUARTERLY = 'quarterly',
}

export interface RevenueChartDataRequest {
  from: string;
  to: string;
  interval: RevenueChartInterval;
  type: RevenueChartDataType;
  filterBy: 'all' | 'organic' | 'paid';
}

export interface RevenueChartDataResponse {
  channels: string[];
  data: {
    date: string;
    [key: string]: string;
  }[];
}

export interface RevenueChannelData {
  name: string;
  goal: number;
}

export type RevenueChannelsResponse = RevenueChannelData[];
