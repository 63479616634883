import { Transition } from '@headlessui/react';
import { useLocation } from 'react-router-dom';
import { Fragment, useEffect, useState } from 'react';

import { logoutUser } from 'helpers/auth';
import useNavPages from 'hooks/useNavPages';

import { ReactComponent as Logo } from 'images/axll-logo.svg';
import { ReactComponent as CloseIcon } from 'icons/x-icon.svg';
import { ReactComponent as LogoutIcon } from 'icons/logout.svg';
import { ReactComponent as HamburgerIcon } from 'icons/hamburger.svg';

import Portal from 'components/shared/Portal';
import NavItem from 'components/sidebar/NavItem';
import NavFooterItem from 'components/sidebar/NavFooterItem';

const MobileNavigation = () => {
  const [open, setOpen] = useState(false);

  const location = useLocation();
  const pathname = location.pathname;

  const { availablePages, lockedPages, comingSoonPages, getBadge } =
    useNavPages();

  useEffect(() => {
    setOpen(false);
  }, [location.pathname]);

  return (
    <>
      <button
        type="button"
        onClick={() => setOpen(true)}
        className="lg:hidden h-6 w-6 inline-flex items-center justify-center text-axll-dark-grey"
      >
        <HamburgerIcon className="w-6" />
      </button>
      <Portal>
        <Transition show={open}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <button
              type="button"
              onClick={() => setOpen(false)}
              className="transition fixed top-0 left-0 h-full w-full bg-gray-500 bg-opacity-75 z-40 backdrop-filter backdrop-blur"
            />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="ease duration-300"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="ease duration-200"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="transition transform fixed top-0 left-0 h-full max-w-[300px] w-3/4 bg-white z-50 overflow-y-auto overscroll-contain py-6">
              <div className="flex items-center justify-between mb-9 px-4">
                <Logo className="h-6" />
                <button
                  className="h-6 w-6 inline-flex items-center justify-center text-axll-dark-grey"
                  type="button"
                  onClick={() => setOpen(false)}
                >
                  <CloseIcon className="h-4" />
                </button>
              </div>
              <div className="mb-6">
                {availablePages.map(({ url, icon, title }) => (
                  <NavItem
                    key={title}
                    active={url === pathname}
                    href={url}
                    icon={icon}
                    badge={getBadge(url)}
                  >
                    {title}
                  </NavItem>
                ))}
              </div>
              {lockedPages.length > 0 && (
                <>
                  <p className="uppercase text-xs text-axll-dark-grey font-medium px-4 tracking-wide mb-4">
                    Locked
                  </p>
                  <div className="mb-6">
                    {lockedPages.map(({ icon, title }) => (
                      <NavItem key={title} disabled icon={icon}>
                        {title}
                      </NavItem>
                    ))}
                  </div>
                </>
              )}
              {comingSoonPages.length > 0 && (
                <>
                  <p className="uppercase text-xs text-axll-dark-grey font-medium px-4 tracking-wide mb-4">
                    Coming soon
                  </p>
                  <div className="mb-4 pb-4 border-b">
                    {comingSoonPages.map(({ icon, title }) => (
                      <NavItem key={title} disabled icon={icon}>
                        {title}
                      </NavItem>
                    ))}
                  </div>
                </>
              )}
              <div>
                <NavFooterItem
                  icon={<LogoutIcon className="h-5" />}
                  onClick={logoutUser}
                >
                  Logout
                </NavFooterItem>
              </div>
            </div>
          </Transition.Child>
        </Transition>
      </Portal>
    </>
  );
};

export default MobileNavigation;
