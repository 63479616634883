import { LOCALSTORAGE_TOKEN_KEY } from 'config/localStorage';

export const setToken = (token: string) => {
  localStorage.setItem(LOCALSTORAGE_TOKEN_KEY, token);
};

export const getToken = () => {
  return localStorage.getItem(LOCALSTORAGE_TOKEN_KEY) || '';
};

export const removeToken = () => {
  return localStorage.removeItem(LOCALSTORAGE_TOKEN_KEY);
};
