interface FooterProps {
  variant?: 'auth' | 'app';
}

const Footer = ({ variant = 'app' }: FooterProps) => {
  const background = variant === 'app' ? 'bg-axll-silver' : 'bg-white';
  const fontSize = variant === 'app' ? 'text-xs' : 'text-sm';

  return (
    <footer
      className={`${
        variant === 'app' ? 'lg:pl-[280px]' : ''
      } w-full text-axll-dark-grey leading-none ${background} ${fontSize}`}
    >
      <div className="container flex flex-col-reverse gap-4 lg:flex-row items-center justify-between py-5 px-6">
        <span>Axll &copy; {new Date().getFullYear()}</span>
        <div className="flex flex-col lg:flex-row items-center gap-6 lg:gap-12">
          <a
            className="inline-block text-center mt-8 lg:mt-0"
            href="mailto:info@axll.com"
          >
            info@axll.com
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
