import { NavItem } from 'types/navigation';

import { ReactComponent as RevenueIcon } from 'icons/revenue.svg';
import { ReactComponent as ProductsIcon } from 'icons/products.svg';
import { ReactComponent as InventoryIcon } from 'icons/inventory.svg';
import { ReactComponent as LocationsIcon } from 'icons/locations.svg';
import { ReactComponent as RetentionIcon } from 'icons/retention.svg';
import { ReactComponent as AnalyticsIcon } from 'icons/analytics.svg';
import { ReactComponent as FinancialsIcon } from 'icons/financials.svg';
import { ReactComponent as InStoreTrackingIcon } from 'icons/instore-tracking.svg';
import { ReactComponent as InventoryPlannerIcon } from 'icons/inventory-planner.svg';

export const routes: NavItem[] = [
  {
    url: '/revenue',
    title: 'Revenue',
    icon: <RevenueIcon className="h-5" />,
  },
  {
    url: '/inventory',
    title: 'Inventory',
    icon: <InventoryIcon className="h-5" />,
  },
  {
    url: '/inventory-planner',
    title: 'Inventory Planner',
    icon: <InventoryPlannerIcon className="h-5" />,
  },
  {
    url: '/retention',
    title: 'Retention',
    icon: <RetentionIcon className="h-5" />,
  },
  {
    url: '/products',
    title: 'Products',
    icon: <ProductsIcon className="h-5" />,
  },
  {
    url: '/locations',
    title: 'Locations',
    icon: <LocationsIcon className="h-5" />,
  },
  {
    url: '/financials',
    title: 'Financials',
    icon: <FinancialsIcon className="h-5" />,
  },
  {
    title: 'Analytics',
    icon: <AnalyticsIcon className="h-5" />,
  },
  {
    title: 'In-Store Tracking',
    icon: <InStoreTrackingIcon className="h-5" />,
  },
];
