import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { createContext, useContext, useEffect, useState } from 'react';

import { User } from 'types/user';
import { getCurrentUser } from 'api/auth';
import { constructURL } from 'helpers/utils';

import ErrorScreen from 'components/shared/ErrorScreen';
import LoadingScreen from 'components/shared/LoadingScreen';

interface AuthContextType {
  user: User;
}

const AuthContext = createContext<AuthContextType>({ user: null! });

const AuthProvider = () => {
  const [user, setUser] = useState<User>(null!);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    let isValid = true;
    getCurrentUser()
      .then((user) => {
        if (!isValid) return;
        if (user) {
          setUser(user);
          if (location.pathname === '/login') {
            navigate('/');
          }
        } else {
          if (location.pathname !== '/login') {
            const searchParams = new URLSearchParams();
            searchParams.set('redirect_to', window.location.href);
            navigate(constructURL('/login', searchParams));
          }
        }
      })
      .catch((error) => {
        if (!isValid) return;
        setError(error.toString());
      })
      .finally(() => {
        if (!isValid) return;
        setLoading(false);
      });
    return () => {
      isValid = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) return <LoadingScreen />;
  if (error) return <ErrorScreen>{error}</ErrorScreen>;

  return (
    <AuthContext.Provider value={{ user }}>
      <Outlet />
    </AuthContext.Provider>
  );
};

export default AuthProvider;

export const useUser = () => useContext(AuthContext);
