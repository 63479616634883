import useNavPages from 'hooks/useNavPages';

import Redirect from './Redirect';

interface ValidatedRedirectProps {
  to: string;
}

const ValidatedRedirect = ({ to }: ValidatedRedirectProps) => {
  const { canVisitURL, availablePages } = useNavPages();

  return <Redirect to={canVisitURL(to) ? to : availablePages[0].url!} />;
};

export default ValidatedRedirect;
