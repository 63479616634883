import dayjs from 'dayjs';
import React, { Suspense, lazy } from 'react';
import { createRoot } from 'react-dom/client';
import smoothscroll from 'smoothscroll-polyfill';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { HelmetProvider } from 'react-helmet-async';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

// Styles
import 'react-datepicker/dist/react-datepicker.css';
import 'style/tailwind.css';
import 'style/style.css';
import 'style/datepicker.css';

// Providers
import AuthProvider from 'providers/AuthProvider';
import MobileProvider from 'providers/MobileProvider';
import NotificationProvider from 'providers/NotificationProvider';
import ProductsPageProvider from 'providers/ProductsPageProvider';
import RetentionPageProvider from 'providers/RetentionPageProvider';
import FinancialsPageProvider from 'providers/FinancialsPageProvider';

// Components
import AppLayout from 'layouts/AppLayout';
import ErrorBoundary from 'components/shared/ErrorBoundary';
import LoadingScreen from 'components/shared/LoadingScreen';
import ValidatedRedirect from 'components/shared/ValidatedRedirect';
import MaintenanceMiddleware from 'middleware/MaintenanceMiddleware';
import EnsureCanVisitPageMiddleware from 'middleware/EnsureCanVisitPageMiddleware';

// Run  the polyfill for smoothscroll on Safari
smoothscroll.polyfill();

dayjs.extend(advancedFormat);

// Pages
const LoginPage = lazy(() => import('pages/Login'));
const ShopifyPage = lazy(() => import('pages/Shopify'));
const RevenuePage = lazy(() => import('pages/Revenue'));
const ProductsPage = lazy(() => import('pages/Products'));
const LocationsPage = lazy(() => import('pages/Locations'));
const InventoryPage = lazy(() => import('pages/Inventory'));
const RetentionPage = lazy(() => import('pages/Retention'));
const OnboardingPage = lazy(() => import('pages/Onboarding'));
const FinancialsPage = lazy(() => import('pages/Financials'));
const ConnectWithShopify = lazy(() => import('pages/ConnectWithShopify'));
const ConnectWithEtsy = lazy(() => import('pages/ConnectWithEtsy'));
const ConnectWithLightspeed = lazy(() => import('pages/ConnectWithLightspeed'));
const ConnectWithTikTok = lazy(() => import('pages/ConnectWithTikTok'));
const ConnectWithAmazon = lazy(() => import('pages/ConnectWithAmazon'));
const InventoryPlannerPage = lazy(() => import('pages/InventoryPlanner'));
const BillingPage = lazy(() => import('pages/Billing'));

const cacheTime = 1000 * 60 * 30; // 30 minutes

const container = document.getElementById('root');
const root = createRoot(container!);
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: cacheTime / 2,
      retry: 2,
      cacheTime,
    },
  },
});

// DISABLING CACHING
// const localStoragePersister = createSyncStoragePersister({
//   storage: window.localStorage,
//   key: LOCALSTORAGE_CACHE_KEY,
//   throttleTime: 5000,
// });

// persistQueryClient({
//   queryClient,
//   persister: localStoragePersister,
//   maxAge: cacheTime,
// });

root.render(
  <React.StrictMode>
    <MaintenanceMiddleware>
      <MobileProvider>
        <NotificationProvider>
          <BrowserRouter>
            <HelmetProvider>
              <QueryClientProvider client={queryClient}>
                <Routes>
                  <Route
                    path="/billing"
                    element={
                      <Suspense fallback={null}>
                        <BillingPage />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/shopify-connect"
                    element={
                      <Suspense fallback={null}>
                        <ConnectWithShopify />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/etsy-connect"
                    element={
                      <Suspense fallback={null}>
                        <ConnectWithEtsy />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/lightspeed-connect"
                    element={
                      <Suspense fallback={null}>
                        <ConnectWithLightspeed />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/tiktok-connect"
                    element={
                      <Suspense fallback={null}>
                        <ConnectWithTikTok />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/amazon-connect"
                    element={
                      <Suspense fallback={null}>
                        <ConnectWithAmazon />
                      </Suspense>
                    }
                  />
                  <Route path="/" element={<AuthProvider />}>
                    <Route
                      path="/onboarding"
                      element={
                        <Suspense fallback={null}>
                          <OnboardingPage />
                        </Suspense>
                      }
                    />
                    <Route path="/" element={<AppLayout />}>
                      <Route
                        path="/"
                        element={<ValidatedRedirect to="/revenue" />}
                      />
                      <Route
                        path="/revenue"
                        element={
                          <Suspense fallback={null}>
                            <EnsureCanVisitPageMiddleware>
                              <ErrorBoundary>
                                <RevenuePage />
                              </ErrorBoundary>
                            </EnsureCanVisitPageMiddleware>
                          </Suspense>
                        }
                      />
                      <Route path="/inventory">
                        <Route
                          path=":id"
                          element={
                            <Suspense fallback={null}>
                              <EnsureCanVisitPageMiddleware url="/inventory">
                                <ErrorBoundary>
                                  <InventoryPage />
                                </ErrorBoundary>
                              </EnsureCanVisitPageMiddleware>
                            </Suspense>
                          }
                        />
                        <Route
                          path=""
                          element={
                            <Suspense fallback={null}>
                              <EnsureCanVisitPageMiddleware>
                                <ErrorBoundary>
                                  <InventoryPage />
                                </ErrorBoundary>
                              </EnsureCanVisitPageMiddleware>
                            </Suspense>
                          }
                        />
                      </Route>
                      <Route
                        path="/financials"
                        element={
                          <Suspense fallback={null}>
                            <EnsureCanVisitPageMiddleware>
                              <ErrorBoundary>
                                <FinancialsPageProvider>
                                  <FinancialsPage />
                                </FinancialsPageProvider>
                              </ErrorBoundary>
                            </EnsureCanVisitPageMiddleware>
                          </Suspense>
                        }
                      />
                      <Route
                        path="/locations"
                        element={
                          <Suspense fallback={null}>
                            <EnsureCanVisitPageMiddleware>
                              <ErrorBoundary>
                                <LocationsPage />
                              </ErrorBoundary>
                            </EnsureCanVisitPageMiddleware>
                          </Suspense>
                        }
                      />
                      <Route
                        path="/retention"
                        element={
                          <Suspense fallback={null}>
                            <EnsureCanVisitPageMiddleware>
                              <ErrorBoundary>
                                <RetentionPageProvider>
                                  <RetentionPage />
                                </RetentionPageProvider>
                              </ErrorBoundary>
                            </EnsureCanVisitPageMiddleware>
                          </Suspense>
                        }
                      />
                      <Route
                        path="/products"
                        element={
                          <Suspense fallback={null}>
                            <EnsureCanVisitPageMiddleware>
                              <ErrorBoundary>
                                <ProductsPageProvider>
                                  <ProductsPage />
                                </ProductsPageProvider>
                              </ErrorBoundary>
                            </EnsureCanVisitPageMiddleware>
                          </Suspense>
                        }
                      />
                      <Route
                        path="/shopify"
                        element={
                          <Suspense fallback={null}>
                            <EnsureCanVisitPageMiddleware>
                              <ErrorBoundary>
                                <ShopifyPage />
                              </ErrorBoundary>
                            </EnsureCanVisitPageMiddleware>
                          </Suspense>
                        }
                      />
                      <Route
                        path="/inventory-planner"
                        element={
                          <Suspense fallback={null}>
                            <EnsureCanVisitPageMiddleware>
                              <ErrorBoundary>
                                <InventoryPlannerPage />
                              </ErrorBoundary>
                            </EnsureCanVisitPageMiddleware>
                          </Suspense>
                        }
                      />
                    </Route>
                    <Route
                      path="/login"
                      element={
                        <Suspense fallback={<LoadingScreen />}>
                          <LoginPage />
                        </Suspense>
                      }
                    />
                  </Route>
                </Routes>
              </QueryClientProvider>
            </HelmetProvider>
          </BrowserRouter>
        </NotificationProvider>
      </MobileProvider>
    </MaintenanceMiddleware>
  </React.StrictMode>,
);
