import { createContext, FC, ReactNode, useContext, useState } from 'react';

import { noop } from 'helpers/utils';
import { getLast365Days } from 'helpers/date';
import { ProductsInterval, ProductsView } from 'types/products';

const { to: last365DaysTo, from: last365DaysFrom } = getLast365Days();

interface ProductsPageContextState {
  view: ProductsView;
  setView: (value: ProductsView) => unknown;
  interval: ProductsInterval;
  setInterval: (value: ProductsInterval) => unknown;
  from: string;
  to: string;
  setFrom: (value: string) => unknown;
  setTo: (value: string) => unknown;
  selectedProduct: string | null;
  setSelectedProduct: (value: string | null) => unknown;
}

const ProductsPageContext = createContext<ProductsPageContextState>({
  view: ProductsView.SALES,
  setView: noop,
  interval: ProductsInterval.WEEKLY,
  setInterval: noop,
  from: last365DaysFrom,
  to: last365DaysTo,
  setFrom: noop,
  setTo: noop,
  selectedProduct: null,
  setSelectedProduct: noop,
});

const ProductsPageProvider: FC<{ children?: ReactNode }> = ({ children }) => {
  const [selectedProduct, setSelectedProduct] = useState<string | null>(null);
  const [view, setView] = useState<ProductsView>(ProductsView.SALES);
  const [interval, setInterval] = useState<ProductsInterval>(
    ProductsInterval.WEEKLY,
  );

  const [from, setFrom] = useState(last365DaysFrom);
  const [to, setTo] = useState(last365DaysTo);

  return (
    <ProductsPageContext.Provider
      value={{
        view,
        setView,
        interval,
        setInterval,
        from,
        setFrom,
        to,
        setTo,
        selectedProduct,
        setSelectedProduct,
      }}
    >
      {children}
    </ProductsPageContext.Provider>
  );
};

export const useProductsPage = () => useContext(ProductsPageContext);

export default ProductsPageProvider;
