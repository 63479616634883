import { useQuery } from '@tanstack/react-query';

import { getChannelColors } from 'api/colors';

const useChannelColors = () => {
  const queryData = useQuery(['colors'], getChannelColors);
  return {
    ...queryData,
    data: queryData.data!,
  };
};

export const DEFAULT_CHANNEL_COLOR = '#113561';

export default useChannelColors;
